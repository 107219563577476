import { Component } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import Banner from './components/Banner';
import { LoginState } from './UserManagement';
import { GameStates } from './GameState';
import { EntryStates } from './EntryState';
import SecondaryButton from './components/SecondaryButton/SecondaryButton';
import tracker from './tracker-interface';
import styles from './SCSS/ToolBar.module.scss';
import { logout } from './GlobalFunctions';

const logoutTooltip = <Tooltip id='tooltip'>Sign Out</Tooltip>;

const signUpTooltip = <Tooltip id='tooltip2'>Sign Up for an Account</Tooltip>;

class ToolBar extends Component {
  logout = () => {
    // tracker.reset();
    this.props.history.push('/logout');
  };

  goToLibrarySignUp = async () => {
    logout(() => this.props.history.push('/tryout'));
  };

  render() {
    let toolbarMod;
    const { requestOid } = this.props.tryoutProfile || { requestOid: '' };
    const shouldShowTryoutToolbar =
      this.props.entryState === EntryStates.tryoutMode &&
      this.props.gameState === GameStates.playingTryoutMode;

    if (shouldShowTryoutToolbar) {
      toolbarMod = styles['toolbar-tryout'];
    } else {
      toolbarMod =
        this.props.gameState === GameStates.playing
          ? styles['toolbar-playing']
          : styles['toolbar-not-playing'];
    }

    return (
      <>
        {this.props.loginState !== LoginState.loggedIn && (
          <Banner isLoggedIn={false} />
        )}
        <div
          className={classNames(
            styles['toolbar'],
            toolbarMod,
            this.props.className,
          )}
        >
          {this.props.loginState === LoginState.loggedIn && (
            <OverlayTrigger placement='right' overlay={logoutTooltip}>
              <span onClick={this.logout} role='button' tabIndex={0}>
                <svg className='fas fa-sign-out-alt' />
              </span>
            </OverlayTrigger>
          )}
          {shouldShowTryoutToolbar && (
            <>
              {requestOid ? (
                <span className={styles.item}>
                  Remind your parent to read their email and create an account!
                </span>
              ) : (
                <>
                  <span className={styles.item}>
                    Do you enjoy myPeekaville? Sign up to save your progress!
                  </span>
                  <OverlayTrigger
                    className={styles.item}
                    placement='right'
                    overlay={signUpTooltip}
                  >
                    <SecondaryButton
                      onClick={this.goToLibrarySignUp}
                      tabIndex={0}
                      tiny
                      noborder
                    >
                      Sign Up
                    </SecondaryButton>
                  </OverlayTrigger>
                </>
              )}
            </>
          )}
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  loginState: state.user.loginState,
  gameState: state.game.gameState,
  entryState: state.entry.entryState,
  tryoutProfile: state.entry.tryoutProfile,
});

export default connect(mapStateToProps, null)(ToolBar);
