import myPeekavilleLogo from './images/myPeekaville-logo.png';
import AppleAppStoreBadge from './components/AppleAppStoreBadge';
import GooglePlayStoreBadge from './components/PlayStoreBadge';
import InfoSegment from './InfoSegment';
import Footer from './Footer';
import styles from './SCSS/AppDownloadRedirect.module.scss';

const AppDownloadRedirect = () => {
  return (
    <>
      <div className={styles.background}>
        <div className={styles.messageBox}>
          <img
            className={styles.logo}
            src={myPeekavilleLogo}
            alt='myPeekapakville Logo'
          />
          <div className={styles.paragraphContainer}>
            <p className={styles.paragraph}>
              It looks like you're trying to access myPeekaville from a mobile
              device or tablet. For the best experience, please go to the App
              Store or Google Play to download myPeekaville.
            </p>
          </div>
          <div className={styles.buttonsContainer}>
            <AppleAppStoreBadge />
            <GooglePlayStoreBadge />
          </div>
        </div>
      </div>
      <InfoSegment id='nextSegment' />
      <Footer />
    </>
  );
};

export default AppDownloadRedirect;
