import { createElement } from 'react';
import { ComponentType } from 'react';
import { Redirect, Route } from 'react-router-dom';
import { isEntryThroughTryout, isUserAuthenticated } from './GlobalFunctions';
type Props = {};

const Authenticated = ({
  component,
  ...rest
}: {
  component: ComponentType<Props>;
}) => (
  <Route
    render={(props) => {
      return isUserAuthenticated() || isEntryThroughTryout() ? (
        createElement(component, { ...rest, ...props })
      ) : (
        <Redirect to='/login' />
      );
    }}
  />
);

export default Authenticated;
