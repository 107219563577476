import styles from './AppStoreBadge.module.scss';

interface Props {
  imageUrl: string;
  altText: string;
  targetUrl: string;
  id?: string;
}

const AppStoreBadge = ({ id, imageUrl, altText, targetUrl }: Props) => {
  return (
    <img
      className={styles.badge}
      src={imageUrl}
      alt={altText}
      onClick={() => window.open(targetUrl, '_blank')}
      id={id}
    />
  );
};

export default AppStoreBadge;
