import Spinner from 'react-spinkit';
import './SpinnerOverlay.css';

export const SpinnerOverlay = ({ isShow }) =>
  isShow ? (
    <div className='spinnerWrapper'>
      <div className='absCentre vcenter spinner'>
        <Spinner name='three-bounce' />
      </div>
    </div>
  ) : null;

export default SpinnerOverlay;
