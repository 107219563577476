import StandalonePageWrapper from './StandalonePageWrapper';
import PeekapakButton from './PeekapakButton';
import NormalLink from './NormalLink';
import tracker from './tracker-interface';
import artwork from './images/disappointed-zoey.png';
import styles from './SCSS/NoParentalConsent.module.scss';
import dialogStyles from './SCSS/Dialog.module.scss';
import { LoginState } from './UserManagement';
import { Auth } from 'aws-amplify';
import { connect } from 'react-redux';

const NonExistentAccountPage = (props) => {
  return (
    <StandalonePageWrapper>
      <div className={styles.noParentalConsent}>
        <div className={styles.noParentalConsentContentLayout}>
          <div className='textBlock'>
            <p className={dialogStyles.dialogTitle}>Sorry!</p>
            <p>
              You don't actually have an account set up for myPeekaville yet!
            </p>
            <p>
              If you're a student, please ask your teacher to set up your
              account at peekapak.com Then you'll be able to play right away!
            </p>
            <p>
              If your teacher has any questions, they can contact us at{' '}
              <NormalLink url='mailto:hello@peekapak.com'>
                hello@peekapak.com
              </NormalLink>{' '}
              for help.
            </p>
            <p className={styles.button}>
              <PeekapakButton
                onClick={() => {
                  if (
                    props.loginState === LoginState.loggedIn ||
                    props.loginState === LoginState.authenticated
                  ) {
                    // setTimeout(()=>Auth.signOut(), 5000);
                    Auth.signOut().catch(() => {
                      // TODO: implement sentry in myPeekaville
                      // logger.logException( error );
                    });
                  } else {
                    props.history.push('/');
                  }
                }}
                bsSize='large'
              >
                Okay
              </PeekapakButton>
            </p>
          </div>
          <img
            className={styles.illustration}
            src={artwork}
            alt='Zoey is disappointed'
            objectFit='contain'
          />
        </div>
      </div>
    </StandalonePageWrapper>
  );
};

const mapStateToProps = (state) => ({
  loginState: state.user.loginState,
});

export default tracker.withPageView(
  connect(mapStateToProps, null)(NonExistentAccountPage)
);
