import Spinner from 'react-spinkit';
import './SpinnerContainer.css';

export const SpinnerContainer = ({ isShow }) =>
  !isShow ? null : (
    <div className='spinnerWrapper'>
      <div className='absCentre vcenter spinner'>
        <Spinner name='three-bounce' />
      </div>
    </div>
  );

export default SpinnerContainer;
