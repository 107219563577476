import styles from './SCSS/ProgressBarOverlay.module.scss';

interface Props {
  progress: number;
  width: string;
}

export const ProgressBarOverlay = ({ progress, width, style }: Props) => {
  interface FillStyle {
    width?: string;
    borderTopRightRadius?: string;
    borderBottomRightRadius?: string;
  }

  const fillStyle = (() => {
    const t: FillStyle = {};
    t.width = `${progress * 100}%`;
    if (progress === 1.0) {
      t.borderTopRightRadius = '5px';
      t.borderBottomRightRadius = '5px';
    }

    return t;
  })();

  return (
    <div className={styles.container} style={style}>
      <div className={styles.progressBar} style={{ width }}>
        <div className={styles.progressBarFill} style={fillStyle} />
      </div>
      <div className={styles.progressBarText}>
        <span className={styles.progressBarTextValue}>
          {`${Math.floor(progress * 100)}`}%
        </span>
        <span className={styles.progressBarLabel}> Completed</span>
      </div>
    </div>
  );
};
