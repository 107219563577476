import { Link } from 'react-router-dom';
import tracker from './tracker-interface';
import './GamePortal.css';

const GamePortal = () => (
  <div className='GamePortal-container'>
    <Link style={{ backgroundColor: 'transparent' }} to='/game'>
      <button>Enter Peekaville</button>
    </Link>
  </div>
);

export default tracker.withPageView(GamePortal);
