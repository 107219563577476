import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export enum EntryStates {
  normal = 'NORMAL',
  tryoutMode = 'TRYOUT_MODE',
}

export enum ActionType {
  ENTER_TRYOUT_MODE = 'enterTryout',
  LEAVE_TRYOUT_MODE = 'leaveTryout',
  SET_TRYOUT_PROFILE = 'setTryoutProfile',
}

export const initialState = {
  entryState: EntryStates.normal,
  tryoutProfile: null,
};

export const entry = createSlice({
  name: 'entry',
  initialState,
  reducers: {
    [ActionType.ENTER_TRYOUT_MODE]: (state) => {
      state.entryState = EntryStates.tryoutMode;
    },
    [ActionType.LEAVE_TRYOUT_MODE]: (state) => {
      state.entryState = EntryStates.normal;
    },
    [ActionType.SET_TRYOUT_PROFILE]: (state, action: PayloadAction<any>) => {
      state.tryoutProfile = action.payload;
    },
  },
});

export const { enterTryout, leaveTryout, setTryoutProfile } = entry.actions;

export default entry.reducer;
