import { useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { LoginState } from './UserManagement';
import { Form, Formik, FormikProps } from 'formik';
import * as Yup from 'yup';
import mailIcon from '@iconify/icons-ci/mail';
import eyeOutline from '@iconify/icons-eva/eye-outline';
import eyeOff2Fill from '@iconify/icons-eva/eye-off-2-fill';
import TextInput from './components/TextInput';
import PrimaryButton from './components/PrimaryButton/PrimaryButton';
import AppleAppStoreBadge from './components/AppleAppStoreBadge';
import GooglePlayStoreBadge from './components/PlayStoreBadge';
import peekaLogo from './images/peekapak-logo-white.png';
import googleLogo from './images/google-logo.png';
import cleverLogo from './images/clever-logo.png';
import classLinkLogo from './images/classlink-logo.png';
import divider from './images/or-divider.png';
import { play } from './GameState';
import styles from './SCSS/LoginDisplayV2.module.scss';
import textStyles from './SCSS/NonmemberLinks.module.scss';

interface FormValues {
  username: string;
  password: string;
}

const validationSchema = Yup.object().shape({
  username: Yup.string().required('Please enter your username'),
  password: Yup.string().required('Please enter your password'),
});

const LoginDisplayV2 = (props: any): JSX.Element => {
  const [showPassword, setShowPassword] = useState(false);

  const initialFormValues: FormValues = {
    username: '',
    password: '',
  };

  const enterMyPeekaville = () => {
    props.onPlay();
    props.history.push('/game');
  };

  const inputRef = useRef<FormikProps<string>>(null);

  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
    inputRef.current.focus();
  };

  /***
   * Uncomment to debug login state
   *
  console.debug(
    `%cLDV2: ${props.loginState.toString()}`,
    'color: green; background-color: black',
  );

   * 
   */

  switch (props.loginState) {
    case LoginState.loggedIn:
      return (
        <div className={styles.loginDisplay}>
          <div className={textStyles.nonMemberLinks}>
            <p className={styles.paragraph}>
              Learn all about important social-emotional skills like empathy,
              teamwork and perseverance through play!
            </p>
          </div>
          <div className={styles.enterContainer}>
            <PrimaryButton
              orange
              type='submit'
              onClick={enterMyPeekaville}
              className={styles.submitButton}
            >
              Play Now
            </PrimaryButton>
            <p className={styles.version}>
              <Link to='/version'>
                {'v' + process.env.REACT_APP_VERSION || ''}
              </Link>
            </p>
          </div>
        </div>
      );
    case LoginState.loggedOut:
    case LoginState.expiredSession:
      return (
        <div className={styles.loginDisplay}>
          <div className={styles.formContainer}>
            <Formik
              initialValues={initialFormValues}
              onSubmit={props.myOnSubmit}
              validationSchema={validationSchema}
            >
              <Form className={styles.form}>
                <p className={styles.title}>Account Login</p>
                <p className={styles.paragraph}>
                  Learn all about important social-emotional skills like
                  empathy, teamwork and perseverance through play!
                </p>
                <div className={styles.formInput}>
                  <TextInput
                    label='Username'
                    name='username'
                    id='username'
                    type='text'
                    placeholder=''
                    autoFocus
                    icon={mailIcon}
                  />
                  <TextInput
                    label='Password'
                    name='password'
                    id='password'
                    type={showPassword ? 'text' : 'password'}
                    placeholder='Passwords are case-sensitive'
                    icon={showPassword ? eyeOutline : eyeOff2Fill}
                    iconClassName={styles.iconStyle}
                    ref={inputRef}
                    clickable
                    onClickIcon={handleTogglePassword}
                  />
                </div>
                <div className={styles.loginStatus}>
                  {props.loginStatusMessage}
                </div>
                <div className={styles.buttons}>
                  <PrimaryButton className={styles.submitButton} type='submit'>
                    <img src={peekaLogo} alt='peekapak logo' />
                    {props.getLoginButtonText}
                  </PrimaryButton>
                  <PrimaryButton
                    className={styles.googleButton}
                    onClick={props.onGoogleSignIn}
                  >
                    <img src={googleLogo} alt='google logo' />
                    Log In with Google
                  </PrimaryButton>
                  <PrimaryButton
                    className={styles.cleverButton}
                    onClick={props.onCleverSignIn}
                  >
                    <img src={cleverLogo} alt='clever logo' />
                    Log In with Clever
                  </PrimaryButton>
                  <PrimaryButton
                    className={styles.classLinkButton}
                    onClick={props.onClassLinkSignIn}
                  >
                    <img src={classLinkLogo} alt='classlink logo' />
                    Log In with ClassLink
                  </PrimaryButton>
                </div>
                <div className={styles.storeBadges}>
                  <AppleAppStoreBadge />
                  <img className={styles.divider} src={divider} alt='divider' />
                  <GooglePlayStoreBadge />
                </div>
                {/* <div className={styles.passwordReset}>
                  Forgot your password? Reset now
                </div> */}

                <p className={styles.version}>
                  <Link to='/version'>
                    {'v' + process.env.REACT_APP_VERSION || ''}
                  </Link>
                </p>
              </Form>
            </Formik>
          </div>
        </div>
      );
    default:
      return (
        <>
          <div className={styles.loginDisplay}>
            <div className={textStyles.nonMemberLinks}>
              <p className={styles.paragraph}>
                Learn all about important social-emotional skills like empathy,
                teamwork and perseverance through play!
              </p>
            </div>
            <div className={styles.enterContainer}>
              <p className={styles.paragraph}>Checking your account...</p>
            </div>
          </div>
        </>
      );
  }
};
const mapStateToProps = (state: any) => ({
  loginState: state.user.loginState,
});

const mapDispatchToProps = (dispatch: (callback: any) => void) => ({
  onPlay: () => dispatch(play()),
});

export default connect(mapStateToProps, mapDispatchToProps)(LoginDisplayV2);
