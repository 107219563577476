import { withRouter } from 'react-router-dom';
import styles from './SCSS/PeekavilleLogoBanner.module.scss';
import logo from './images/myPeekaville-logo.png';

const PeekavilleLogoBanner = (props) => (
  <div className={styles.peekavilleLogoBanner}>
    <img
      src={logo}
      alt='myPeekaville logo'
      onClick={() => {
        console.debug('click');
        props.history.push('/login');
      }}
      style={{ cursor: 'pointer' }}
    />
  </div>
);

export default withRouter(PeekavilleLogoBanner);
