import PropTypes from 'prop-types';
import Spinner from 'react-spinner';
import '../node_modules/react-spinner/react-spinner.css';

const myStyle = {
  height: '3em',
  width: '3em',
  backgroundColor: 'transparent',
};

const MySpinner = ({ isShow }) =>
  isShow ? (
    <div style={myStyle}>
      <Spinner />
    </div>
  ) : null;

MySpinner.propTypes = {
  isShow: PropTypes.bool.isRequired,
};

export default MySpinner;
