import cx from 'classnames';
import Documents from './Documents';
import whiteLogo from './images/pkp-backpack-name-logo.svg';
import styles from './SCSS/Footer.module.scss';

const Footer = () => {
  return (
    <footer className={styles.footer}>
      <nav className={styles.navigation}>
        <section className={styles.columns}>
          <h4>SEL Resources</h4>
          <p>
            <a href='https://www.peekapak.com/elementary' target='_blank'>
              Elementary SEL
            </a>
          </p>
          <p>
            <a href='https://www.peekapak.com/secondary' target='_blank'>
              Secondary SEL
            </a>
          </p>
          <p>
            <a href='https://www.peekapak.com/mypeekaville#top' target='_blank'>
              SEL Learning Game
            </a>
          </p>
          <p>
            <a href={Documents.shopSite} target='_blank'>
              SEL Print Books
            </a>
          </p>
        </section>
        <section className={styles.columns}>
          <h4>About Us</h4>
          <p>
            <a href='https://www.peekapak.com/about#top' target='_blank'>
              Our Story
            </a>
          </p>
          <p>
            <a href={Documents.blogSite} target='_blank'>
              SEL Blog
            </a>
          </p>
          <p>
            <a href={Documents.careersSite} target='_blank'>
              Careers
            </a>
          </p>
          <p>
            <a href='https://www.peekapak.com/press' target='_blank'>
              Press
            </a>
          </p>
        </section>
        <section className={styles.columns}>
          <h4>Resources</h4>
          <p>
            <a href='https://help.peekapak.com/en/' target='_blank'>
              FAQs
            </a>
          </p>
          <p>
            <a href='https://www.peekapak.com/parents#top' target='_blank'>
              Parents
            </a>
          </p>
        </section>
        <section className={styles.columns}>
          <h4>Legal</h4>
          <p>
            <a
              href='https://www.peekapak.com/privacyPolicy#top'
              target='_blank'
              className={styles.privacyPolicy}
            >
              Privacy Policy
            </a>
          </p>
          <p>
            <a href='https://www.peekapak.com/termsOfUse#top' target='_blank'>
              Terms of Use
            </a>
          </p>
        </section>
      </nav>
      <section className={cx(styles.contactCard, 'vcard')}>
        <figure>
          <object data={whiteLogo} type='image/svg+xml'>
            white backpack word logo
          </object>
          <figcaption>
            <p
              className='fn org'
              style={{
                display: 'none',
              }}
            >
              Peekapak Inc.
            </p>
            <p>
              <a className='email' href='mailto:hello@peekapak.com'>
                hello@peekapak.com
              </a>
              <br />
              <span className='adr'>
                <span className='street-address'>
                  192 Spadina Ave Suite 300,
                </span>
                <br />
                <span className='locality'>Toronto</span>
              </span>
            </p>
            <a className='tel' href='tel:+18885136418'>
              1-888-513-6418
            </a>
            <div className={styles.socialMediaIcons}>
              <a href='http://facebook.com/peekapak' target='_blank'>
                <i className='fab fa-facebook-f' />
              </a>
              <a href='http://twitter.com/peekapak' target='_blank'>
                <i className='fab fa-twitter' />
              </a>
            </div>
          </figcaption>
        </figure>
      </section>
    </footer>
  );
};

export default Footer;
