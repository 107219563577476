import axios, { AxiosError } from 'axios';
import { convertAxiosError } from './BackendInterface';
import {
  IdpType,
  UserProfileType,
  UserType,
} from './peekapak-types/DataProtocolTypes';
import {
  EventName,
  LoginEvent,
  LogEventRequest,
  AnalyticsEvent,
  StudentEvent,
  EventSource,
  NonStudentEvent,
  InteractionEndTrigger,
  InteractionStartEvent,
  InteractionEndEvent,
} from './peekapak-types/AnalyticsTypes';
import { getAuthorizationToken } from './BackendInterface';

const analyticsServicesRoot = (() => {
  if (process.env.REACT_TEST_PROD_API) {
    return 'https://api.peekapak.com/analyticsservices';
  } else if (
    process.env.NODE_ENV === 'development' ||
    process.env.REACT_APP_USE_DEV_API
  ) {
    return 'https://api.peekapak.com/analyticsservicesdev';
  } else {
    return 'https://api.peekapak.com/analyticsservices';
  }
})();

export const Analytics = {
  interactionStart: async (userProfile: UserProfileType) => {
    const commonProperties = extractCommonUserProperties(userProfile);
    const { userType, userId } = commonProperties;

    const propertiesToLog = (() => {
      if (userType === UserType.STUDENT) {
        const { grade, readingLevel, classroomId } = userProfile;
        const interactionStartProperties: InteractionStartEvent = {
          name: EventName.INTERACTION_START,
          source: EventSource.MYPEEKAVILLE,
          grade,
          readingLevel,
          classroomId,
          ...commonProperties,
          userType,
        };

        const properties = {
          ...interactionStartProperties,
          distinct_id: userId,
        };

        return properties;
      } else {
        const interactionStartProperties: InteractionStartEvent = {
          name: EventName.INTERACTION_START,
          source: EventSource.MYPEEKAVILLE,
          ...commonProperties,
          userType,
        };

        const properties = {
          ...interactionStartProperties,
          distinct_id: userId,
        };

        return properties;
      }
    })();

    await logEvent(propertiesToLog);
  },
  interactionEnd: async (
    userProfile: UserProfileType,
    duration: number,
    trigger: InteractionEndTrigger,
  ) => {
    const commonProperties = extractCommonUserProperties(userProfile);
    const { userType, userId } = commonProperties;

    const propertiesToLog = (() => {
      if (userType === UserType.STUDENT) {
        const { grade, readingLevel, classroomId } = userProfile;
        const interactionEndProperties: InteractionEndEvent = {
          name: EventName.INTERACTION_END,
          source: EventSource.MYPEEKAVILLE,
          grade,
          readingLevel,
          classroomId,
          ...commonProperties,
          userType,
          duration,
          trigger,
        };

        const properties = {
          ...interactionEndProperties,
          distinct_id: userId,
        };

        return properties;
      } else {
        const interactionEndProperties: InteractionEndEvent = {
          name: EventName.INTERACTION_END,
          source: EventSource.MYPEEKAVILLE,
          ...commonProperties,
          userType,
          duration,
          trigger,
        };

        const properties = {
          ...interactionEndProperties,
          distinct_id: userId,
        };

        return properties;
      }
    })();

    await logEventWithFetchKeepalive(propertiesToLog);
  },
  login: async (userProfile: UserProfileType, method?: IdpType) => {
    const commonProperties = extractCommonUserProperties(userProfile);
    const { userType, userId } = commonProperties;

    const propertiesToLog = (() => {
      if (userType === UserType.STUDENT) {
        const { grade, readingLevel, classroomId } = userProfile;
        const loginProperties: LoginEvent<StudentEvent> = {
          name: EventName.LOGIN,
          source: EventSource.MYPEEKAVILLE,
          grade,
          readingLevel,
          classroomId,
          ...commonProperties,
          userType,
          loginMethod: method || 'unknown',
        };

        const properties = {
          ...loginProperties,
          distinct_id: userId,
        };

        return properties;
      } else {
        const loginProperties: LoginEvent<NonStudentEvent> = {
          name: EventName.LOGIN,
          source: EventSource.MYPEEKAVILLE,
          ...commonProperties,
          userType,
          loginMethod: method || 'unknown',
        };

        const properties = {
          ...loginProperties,
          distinct_id: userId,
        };

        return properties;
      }
    })();

    await logEvent(propertiesToLog);
  },
  pageView: async (
    url: string,
    pageName: string | null = null,
    userProfile: UserProfileType | null = null,
  ) => {
    const userProperties = (() => {
      if (userProfile) {
        const commonProperties = extractCommonUserProperties(userProfile);
        const { userType, userId } = commonProperties;

        const propertiesToLog = (() => {
          if (userType === UserType.STUDENT) {
            const { grade, readingLevel, classroomId } = userProfile;
            const loginProperties: LoginEvent<StudentEvent> = {
              name: EventName.LOGIN,
              source: EventSource.MYPEEKAVILLE,
              grade,
              readingLevel,
              classroomId,
              ...commonProperties,
              userType,
              loginMethod: 'unknown',
            };

            const properties = {
              ...loginProperties,
              distinct_id: userId,
            };

            return properties;
          } else {
            const loginProperties: LoginEvent<NonStudentEvent> = {
              name: EventName.LOGIN,
              source: EventSource.PEEKAPAK,
              ...commonProperties,
              userType,
              loginMethod: 'unknown',
            };

            const properties = {
              ...loginProperties,
              distinct_id: userId,
            };

            return properties;
          }
        })();
        return propertiesToLog;
      }

      return {};
    })();

    const propertiesToLog = {
      ...userProperties,
      name: EventName.PAGE_VIEW,
      source: EventSource.PEEKAPAK,
      url,
      pageName: pageName || 'none',
    };

    logEvent(propertiesToLog);
  },
};

function extractCommonUserProperties(userProfile: UserProfileType) {
  const {
    type,
    userId,
    schoolId,
    district,
    account,
    seller,
    licenseLevel,
    licenseExpires,
  } = userProfile;

  return {
    userType: type as UserType,
    userId,
    schoolId: schoolId || 'none',
    district: district || 'none',
    account: account || 'none',
    seller: seller || 'none',
    licenseLevel,
    licenseExpires,
  };
}

async function logEvent(event: AnalyticsEvent) {
  const auth = await getAuthorizationToken();
  const endpoint = `${analyticsServicesRoot}/analytics/log`;
  const request: LogEventRequest = {
    event,
  };
  try {
    await axios({
      method: 'post',
      url: endpoint,
      data: request,
      headers: {
        Authorization: auth,
      },
    });
  } catch (error) {
    console.error(
      `Error communicating with server: ${convertAxiosError(
        error as AxiosError,
      )}`,
    );
    throw convertAxiosError(error as AxiosError);
  }
}

//utilize fetch to allow for keepalive flag, not currently supported by axios
async function logEventWithFetchKeepalive(event: AnalyticsEvent) {
  const auth = await getAuthorizationToken();
  const endpoint = `${analyticsServicesRoot}/analytics/log`;
  const request: LogEventRequest = {
    event,
  };
  try {
    await fetch(endpoint, {
      keepalive: true,
      method: 'post',
      body: JSON.stringify(request),
      headers: {
        Authorization: auth,
      },
    });
  } catch (error) {
    console.error(
      `Error communicating with server: ${convertAxiosError(
        error as AxiosError,
      )}`,
    );
    throw convertAxiosError(error as AxiosError);
  }
}

export default Analytics;
