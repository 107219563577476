import { ComponentPropsWithoutRef, forwardRef } from 'react';
import { useField, FieldHookConfig } from 'formik';
import { Icon, IconifyIcon } from '@iconify/react';
import cx from 'classnames';
import styles from './TextInput.module.scss';

interface Props extends React.HTMLProps<HTMLInputElement> {
  fullBorder?: boolean;
  label?: string;
  inputClassName?: string;
  icon?: IconifyIcon;
  iconClassName?: string;
  clickable?: boolean;
  onClickIcon?: () => void;
}

const TextInput = forwardRef<
  HTMLInputElement,
  ComponentPropsWithoutRef<'input'>
>(
  (
    {
      fullBorder,
      label,
      inputClassName,
      icon,
      iconClassName,
      clickable,
      onClickIcon,
      ...props
    }: Props & FieldHookConfig<string>,
    ref
  ): JSX.Element => {
    const [field, meta] = useField(props);
    return (
      <div className={styles.container}>
        {label && (
          <label className={styles.label} htmlFor={props.name}>
            {label}
          </label>
        )}
        <span className={styles.inputField}>
          <input
            className={cx(styles.input, inputClassName, {
              [`${styles.fullBorder}`]: fullBorder,
            })}
            {...field}
            {...props}
            ref={ref}
          />
          {icon && (
            <Icon
              className={
                clickable
                  ? cx(styles.icon, styles.pointer, iconClassName)
                  : cx(styles.icon, iconClassName)
              }
              icon={icon}
              onClick={onClickIcon}
            />
          )}
        </span>
        {meta.touched && meta.error ? (
          <div className={styles.errorHint}>{meta.error}</div>
        ) : null}
      </div>
    );
  }
);

export default TextInput;
