import lscache from 'lscache';
import { getStore, myBrowserHistory } from './ApplicationState';
import { expireSession } from './UserManagement';

export let _queryObject; // Includes all arguments in the url in an object form
export let _prefRole; // the preferred role based on source
export let isTrial;

export const setCookie = (
  cname: string,
  cvalue: string,
  exdays?: number,
  path?: string
) => {
  const d = new Date();
  d.setTime(d.getTime() + (exdays ?? 1) * 24 * 60 * 60 * 1000);
  const expires = `expires=${d.toUTCString()}`;

  const encodedValue = encodeURIComponent(cvalue);
  // console.debug( 'encodedValue', encodedValue );
  document.cookie = `${cname}=${encodedValue}; ${expires}${
    path ? `;path=${path}` : ';path=/;'
  }`;
};

export const getCookie = (cname: string) => {
  const name = `${cname}=`;
  const ca = document.cookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      const decodedValue = decodeURIComponent(
        c.substring(name.length, c.length)
      );

      // console.debug( 'decodedValue', decodedValue );
      return decodedValue;
    }
  }
  return '';
};

export const deleteCookie = (cname: string) => {
  document.cookie = `${cname}=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
};

export const setLocalStorage = (
  keyName: string,
  value: string,
  expiry?: number
) => {
  if (expiry) {
    lscache.set(`peekapak.${keyName}`, value, expiry);
  } else {
    lscache.set(`peekapak.${keyName}`, value);
  }
};

export const getLocalStorage = (keyName: string) => {
  return lscache.get(`peekapak.${keyName}`);
};

export const removeLocalStorage = (keyName: string) => {
  return lscache.remove(`peekapak.${keyName}`);
};

export const flushLocalStorage = () => {
  return lscache.flush();
};

function expireCurrentSession() {
  if (window.location.pathname !== '/sessionExpired') {
    setCookie('peekapak.pathBeforeSessionExpired', window.location.pathname, 7);
  }

  getStore().dispatch(expireSession());
}

export function expireSessionAndRedirect(
  isRedirectToExpiredSessionPage = true
) {
  console.debug(`expireSessionAndRedirect called`);
  // console.trace();
  if (isRedirectToExpiredSessionPage) {
    console.debug(
      `Pushing to session expired from `,
      myBrowserHistory.location.pathname
    );

    myBrowserHistory.push('/sessionExpired');
  }

  // expireCurrentSession which resets the user profile needs to
  // be done after push has occurred otherwise papges that depend on userprofile
  // will crash. We'll see if this actually works or whether it has to be
  // asynchronously done after the page transition has occurred
  expireCurrentSession();
}

export function hasNextPathAfterAuthentication(location) {
  return location && location.state && location.state.nextPathname;
}

export function hasPathBeforeSessionExpired() {
  if (getCookie('peekapak.pathBeforeSessionExpired')) {
    return true;
  } else {
    return false;
  }
}

export function hasPathBeforeLoginRedirect() {
  if (getCookie('peekapak.pathBeforeLoginRedirect')) {
    return true;
  } else {
    return false;
  }
}

export function restorePathBeforeLoginRedirect() {
  const path = getCookie('peekapak.pathBeforeLoginRedirect');
  if (path) {
    deleteCookie('peekapak.pathBeforeLoginRedirect');

    myBrowserHistory.push(path);
  }
}

export function restorePathBeforeSessionExpired() {
  const path = getCookie('peekapak.pathBeforeSessionExpired');
  if (path) {
    deleteCookie('peekapak.pathBeforeSessionExpired');

    myBrowserHistory.push(path);
  }
}
