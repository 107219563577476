import StandalonePageWrapper from './StandalonePageWrapper';
import PeekapakButton from './PeekapakButton';
import tracker from './tracker-interface';
import artwork from './images/disappointed-zoey.png';
import styles from './SCSS/NoParentalConsent.module.scss';
import dialogStyles from './SCSS/Dialog.module.scss';

const DynamicErrorMessage = (props) => (
  <StandalonePageWrapper>
    <div className={styles.noParentalConsent}>
      <div className={styles.noParentalConsentContentLayout}>
        <div className='textBlock'>
          <p className={dialogStyles.dialogTitle}>Sorry!</p>
          {props.message}
          <p className={styles.button}>
            <PeekapakButton onClick={props.onClose} bsSize='large'>
              Okay
            </PeekapakButton>
          </p>
        </div>
        <img src={artwork} alt='Zoey is disappointed' />
      </div>
    </div>
  </StandalonePageWrapper>
);

export default tracker.withPageView(DynamicErrorMessage);
