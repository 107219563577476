import {
  createJournalEntry,
  getExistingAuthorizationToken,
  recordInteractionEvent,
  retrieveGameState,
  sendComprehensionData,
  sendStruggleData,
  storeGameState,
  storeUserGeneratedImage,
} from './BackendInterface';

const getAuthToken3D = (unityGameObject, unityGameObjectMethod) => {
  const token = getExistingAuthorizationToken();

  if (token && peekavilleWebContainer.gameInstance) {
    peekavilleWebContainer.gameInstance.SendMessage(
      unityGameObject,
      unityGameObjectMethod,
      token
    );

    return;
  }

  peekavilleWebContainer.gameInstance.SendMessage(
    unityGameObject,
    unityGameObjectMethod,
    ''
  );
};

const retrieveGameStateForUnity3D = (
  unityGameObject,
  unityGameObjectMethod
) => {
  retrieveGameState(handleGoodResult, () => {
    console.error(`DEBUG: Retrieve game state for Unity WebGL failed`);
    handleBadResult(unityGameObject, unityGameObjectMethod);
  });

  function handleGoodResult(response) {
    if (peekavilleWebContainer.gameInstance) {
      peekavilleWebContainer.gameInstance.SendMessage(
        unityGameObject,
        unityGameObjectMethod,
        JSON.stringify(response)
      );
    }
  }
};

const storeGameStateForUnity3D = (
  newGameState,
  unityGameObject,
  unityGameObjectMethod
) => {
  storeGameState(JSON.parse(newGameState), handleGoodResult, () => {
    console.error(`DEBUG: Store game state for Unity WebGL failed`);
    handleBadResult(unityGameObject, unityGameObjectMethod);
  });

  function handleGoodResult() {
    if (peekavilleWebContainer.gameInstance) {
      peekavilleWebContainer.gameInstance.SendMessage(
        unityGameObject,
        unityGameObjectMethod,
        'success'
      );
    }
  }
};

const createJournalEntryForUnity3D = (
  journalEntry,
  unityGameObject,
  unityGameObjectMethod
) => {
  createJournalEntry(JSON.parse(journalEntry), handleGoodResult, () => {
    console.error(`DEBUG: Store game state for Unity WebGL failed`);
    handleBadResult(unityGameObject, unityGameObjectMethod);
  });

  function handleGoodResult() {
    if (peekavilleWebContainer.gameInstance) {
      peekavilleWebContainer.gameInstance.SendMessage(
        unityGameObject,
        unityGameObjectMethod,
        'success'
      );
    }
  }
};

const sendStruggleDataForUnity3D = (
  struggleData,
  unityGameObject,
  unityGameObjectMethod
) => {
  sendStruggleData(JSON.parse(struggleData), handleGoodResult, () => {
    console.error(`DEBUG: Store struggle data for Unity WebGL failed`);
    handleBadResult(unityGameObject, unityGameObjectMethod);
  });

  function handleGoodResult() {
    if (peekavilleWebContainer.gameInstance) {
      peekavilleWebContainer.gameInstance.SendMessage(
        unityGameObject,
        unityGameObjectMethod,
        'success'
      );
    }
  }
};

const sendComprehensionDataForUnity3D = (
  comprehensionData,
  unityGameObject,
  unityGameObjectMethod
) => {
  sendComprehensionData(JSON.parse(comprehensionData), handleGoodResult, () => {
    console.error(`DEBUG: Store comprehension data for Unity WebGL failed`);
    handleBadResult(unityGameObject, unityGameObjectMethod);
  });

  function handleGoodResult() {
    if (peekavilleWebContainer.gameInstance) {
      peekavilleWebContainer.gameInstance.SendMessage(
        unityGameObject,
        unityGameObjectMethod,
        'success'
      );
    }
  }
};

const signalPlayerSignoutToUnity3D = async () => {
  if (peekavilleWebContainer.gameInstance) {
    peekavilleWebContainer.gameInstance.SendMessage(
      `Singleton<SaveManager>`,
      `OnSignout`
    );
  }
};

const storeUserGeneratedImageForUnity3D = (
  applicationName,
  displayTitle,
  imageAsDataUri,
  unityGameObject,
  unityGameObjectMethod
) => {
  storeUserGeneratedImage(
    applicationName,
    displayTitle,
    imageAsDataUri,
    handleGoodResult,
    () => {
      console.error(
        `DEBUG: Store letters game letter image for Unity WebGL failed`
      );
      handleBadResult(unityGameObject, unityGameObjectMethod);
    }
  );

  function handleGoodResult() {
    if (peekavilleWebContainer.gameInstance) {
      peekavilleWebContainer.gameInstance.SendMessage(
        unityGameObject,
        unityGameObjectMethod,
        'success'
      );
    }
  }
};

const recordInteractionEventForUnity3D = (
  eventType,
  eventName,
  callbackObject,
  callbackObjectMethod
) => {
  recordInteractionEvent(eventType, eventName);
};

function handleBadResult(unityGameObject, unityGameObjectMethod) {
  if (peekavilleWebContainer.gameInstance) {
    peekavilleWebContainer.gameInstance.SendMessage(
      unityGameObject,
      unityGameObjectMethod,
      ''
    );
  }
}

const setupUnityWebGLInterface = () => {
  /* global peekavilleWebContainer:true */
  peekavilleWebContainer.retrieveGameState = retrieveGameStateForUnity3D;
  peekavilleWebContainer.storeGameState = storeGameStateForUnity3D;
  peekavilleWebContainer.createJournalEntry = createJournalEntryForUnity3D;
  peekavilleWebContainer.sendStruggleData = sendStruggleDataForUnity3D;
  peekavilleWebContainer.sendComprehensionData =
    sendComprehensionDataForUnity3D;
  peekavilleWebContainer.signalPlayerSignout = signalPlayerSignoutToUnity3D;
  peekavilleWebContainer.storeUserGeneratedImage =
    storeUserGeneratedImageForUnity3D;
  peekavilleWebContainer.recordInteractionEvent =
    recordInteractionEventForUnity3D;
  peekavilleWebContainer.getAuthToken = getAuthToken3D;
};

export default setupUnityWebGLInterface;
