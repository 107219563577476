import { Button } from 'react-bootstrap';
import combineClasses from 'classnames';
import styles from './SecondaryButton.module.scss';

const SecondaryButton = ({
  small,
  tiny,
  noborder,
  disabled,
  ...otherProps
}) => (
  <div
    className={combineClasses({
      [`${styles.secondaryButton}`]: true,
      [`${styles.small}`]: small,
      [`${styles.tiny}`]: tiny,
      [`${styles.noborder}`]: noborder,
      [`${styles.disabled}`]: disabled,
    })}
  >
    <Button {...otherProps}>{otherProps.children}</Button>
  </div>
);

export default SecondaryButton;
