import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export enum LoginState {
  unknown = 'UNKNOWN',
  reactivating = 'REACTIVATING',
  expiredSession = 'EXPIRED_SESSION',
  authenticated = 'AUTHENTICATED',
  partial = 'LOGIN_PARTIAL',
  loggedIn = 'LOGGED_IN',
  loggedOut = 'LOGGED_OUT',
  storybookAccess = 'STORYBOOK_ACCESS',
}

export enum LicenseLevel {
  basic = 'BASIC',
  teacherPro = 'PRO',
  classroomPro = 'CLASSROOM',
  schoolAdministrator = 'SCHOOL_ADMINISTRATOR',
  districtAdministrator = 'DISTRICT_ADMINISTRATOR',
}

export enum UserType {
  student = 'Student',
  teacher = 'Teacher',
  schoolAdmin = 'SchoolAdmin',
  districtAdmin = 'DistrictAdmin',
}

export enum ActionType {
  LOGIN = 'login',
  EXPIRE_SESSION = 'expireSession',
  START_REACTIVATION = 'startReactivation',
  REACTIVATE_SESSION = 'reactivateSession',
  REFRESH_TOKEN = 'refreshToken',
  LOGIN_PARTIAL = 'loginPartial',
  SET_USER_PROFILE = 'setUserProfile',
  RETRIEVE_USER_PROFILE = 'retrieveUserProfile',
  UPDATE_FIELD = 'updateField',
  UPDATE_ALL_FIELDS = 'updateAllFields',
  LOGOUT = 'logout',
  ACTIVATE_STORYBOOK_ACCESS = 'activateStorybookAccess',
}

export type LoginStateType = typeof LoginState[keyof typeof LoginState];

export interface CognitoUserProfileType {
  username: string;
  attributes: {
    'custom:pmAccessToken'?: string;
    'custom:cleverDistrict': string;
    'custom:cleverSchool': string;
    'custom:cleverUserId': string;
    'custom:pmAccesstoken': string;
    'custom:pmAuthorizedBy': string;
    'custom:pmDistrict': string;
    'custom:pmSchool': string;
    'custom:pmUserId': string;
    'custom:userType': string;
    email: string;
    email_verified: true;
    identities: string;
    sub: string;
    name?: string;
    given_name?: string;
    family_name?: string;
    middle_name?: string;
  };
}

export type UpdateUserProfile = Partial<UserProfileType>;

/*
export type UpdateUserProfile = {
  [Property in keyof UserProfileType]: UserProfileType[Property];
};

export interface FieldUpdatePair {
  field: string;
  value: unknown;
}
*/

export type FieldUpdatePair = {
  field: keyof UserProfileType;
  value: unknown;
};

export interface UserManagementState {
  loginState: LoginStateType;
  cognitoProfile: CognitoUserProfileType | null;
  userProfile: UserProfileType | null;
}

export const initialState: UserManagementState = {
  loginState: LoginState.unknown,
  cognitoProfile: null,
  userProfile: null,
};

export const user = createSlice({
  name: 'user',
  initialState,
  reducers: {
    [ActionType.LOGIN]: (
      state,
      action: PayloadAction<CognitoUserProfileType>
    ) => {
      state.loginState = LoginState.authenticated;
      state.cognitoProfile = action.payload;
    },
    [ActionType.REACTIVATE_SESSION]: (
      state,
      action: PayloadAction<CognitoUserProfileType>
    ) => {
      state.loginState = LoginState.authenticated;
      state.cognitoProfile = action.payload;
    },

    [ActionType.REFRESH_TOKEN]: (
      state,
      action: PayloadAction<CognitoUserProfileType>
    ) => {
      state.cognitoProfile = action.payload;
    },

    [ActionType.START_REACTIVATION]: (state) => {
      state.loginState = LoginState.reactivating;
    },

    [ActionType.LOGIN_PARTIAL]: (state) => {
      state.loginState = LoginState.partial;
    },

    [ActionType.SET_USER_PROFILE]: (
      state,
      action: PayloadAction<UserProfileType>
    ) => {
      state.loginState = LoginState.loggedIn;
      state.userProfile = action.payload;
    },

    [ActionType.RETRIEVE_USER_PROFILE]: (
      state,
      action: PayloadAction<UserProfileType>
    ) => {
      state.loginState = LoginState.loggedIn;
      state.userProfile = action.payload;
    },

    [ActionType.UPDATE_FIELD]: (
      state,
      action: PayloadAction<FieldUpdatePair>
    ) => {
      const { field, value } = action.payload;
      if (state.userProfile) state.userProfile[field] = value;
    },

    [ActionType.UPDATE_ALL_FIELDS]: (
      state,
      action: PayloadAction<UpdateUserProfile>
    ) => {
      state.userProfile = {
        ...state.userProfile,
        ...(action.payload as UserProfileType),
      };
    },
    [ActionType.LOGOUT]: (state) => {
      state.loginState = LoginState.loggedOut;
      state.userProfile = null;
      state.cognitoProfile = null;
    },

    [ActionType.EXPIRE_SESSION]: (state) => {
      state.loginState = LoginState.expiredSession;
      state.userProfile = null;
      state.cognitoProfile = null;
    },

    [ActionType.ACTIVATE_STORYBOOK_ACCESS]: (
      state,
      action: PayloadAction<UserProfileType>
    ) => {
      state.loginState = LoginState.storybookAccess;
      state.userProfile = action.payload;
      state.cognitoProfile = null;
    },
  },
});

export const {
  login,
  expireSession,
  startReactivation,
  reactivateSession,
  refreshToken,
  loginPartial,
  setUserProfile,
  retrieveUserProfile,
  updateField,
  updateAllFields,
  logout,
  activateStorybookAccess,
} = user.actions;

export default user.reducer;
