import { Component } from 'react';
import './InfoSegment.scss';
import avatar_builder from './images/avatar_builder.png';
import avatar_website from './images/avatar_website.jpg';
import checkin_01 from './images/checkin_01.png';
import peekaville_website from './images/peekaville_website.jpg';
import storybook_01 from './images/storybook_01.png';

const LeftImgSection = (props) => (
  <div className={props.className ? props.className : ''}>
    <div className='mainSubItem'>
      <img
        className={props.largeImg ? 'largeImg' : 'rightImg'}
        src={props.iconImage}
        alt='Icon'
      />
      <div className='titleWithText leftPic'>
        <div className='mainContentTitle'>{props.title}</div>
        {props.subTitle && <div className='subtitle'>{props.subTitle}</div>}
        {props.children}
      </div>
    </div>
  </div>
);

const RightImgSection = (props) => (
  <div className={props.className ? props.className : ''}>
    <div className='mainSubItem'>
      <div className='titleWithText rightPic'>
        <div className='mainContentTitle'>{props.title}</div>
        {props.subTitle && <div className='subtitle'>{props.subTitle}</div>}
        {props.children}
      </div>
      <img
        className={props.largeImg ? 'largeImg' : 'rightImg'}
        src={props.iconImage}
        alt='Icon'
      />{' '}
    </div>
  </div>
);

export class InfoSegment extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isShowGetStartedDialog: false,
    };

    this.handleOpenGetStartedDialog =
      this.handleOpenGetStartedDialog.bind(this);
    this.handleCloseGetStartedDialog =
      this.handleCloseGetStartedDialog.bind(this);
  }

  handleOpenGetStartedDialog(e) {
    this.setState({
      isShowGetStartedDialog: true,
    });
  }

  handleCloseGetStartedDialog(e) {
    this.setState({
      isShowGetStartedDialog: false,
    });
  }

  render() {
    return (
      <div id='nextSegment' className='infoSegment'>
        <LeftImgSection
          iconImage={peekaville_website}
          title='Explore the world of Peekaville'
          largeImg
        >
          <div className='mainContentText'>
            You’re invited inside the world of Peekaville, a diverse forest
            village where sunshine sparkles off brightly patterned buildings and
            colourful trees. Here, an air of optimism mingles with the feeling
            that anything is possible, for Peekaville is a place of creativity
            and creation.
          </div>
        </LeftImgSection>
        <RightImgSection
          iconImage={avatar_website}
          title='Embracing Diversity'
          largeImg
        >
          <div className='mainContentText'>
            In Peekaville, kids of all kinds, human and animals alike, learn
            from one another. With such diversity, the kids of Peekaville must
            develop and master crucial skills, like empathy, respect, kindness,
            teamwork, and gratitude so that everyone can live together
            harmoniously.
          </div>
        </RightImgSection>
        <LeftImgSection
          iconImage={avatar_builder}
          title='Put Yourself into the World of myPeekaville'
          largeImg
        >
          <div className='mainContentText'>
            In myPeekaville get started by creating and customizing your own
            avatar. Interact with the Peekapak Pals by helping them solve
            problems through quests, activities and games that incorporate
            social emotional learning, reading and writing.
          </div>
        </LeftImgSection>
        <RightImgSection iconImage={checkin_01} title='Mood Check-in' largeImg>
          <div className='mainContentText'>
            Complete your journal by checking in every day with how you’re
            feeling. Practice explaining your feelings, and recognising them in
            others. Earn more berries by checking in daily.
          </div>
        </RightImgSection>
        <LeftImgSection
          iconImage={storybook_01}
          title='Peekapak Stories'
          largeImg
        >
          <div className='mainContentText'>
            All of our Peekapak stories are available within myPeekaville. Visit
            the library to gain access to each book. Read about all of the
            Peekapak Pals exciting adventures. Unlock new stories, and
            characters to visit as you progress through the world.
          </div>
        </LeftImgSection>
      </div>
    );
  }
}

export default InfoSegment;
