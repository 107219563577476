import { Component } from 'react';
import reformed from 'react-reformed';
import Spinner from './MySpinner';
import { sendConsoleCommand } from './BackendInterface';

class ConsoleInputForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isSending: false,
      buttonText: 'Send Command',
      statusMessage: '',
    };
  }

  myOnSubmit = (event) => {
    event.preventDefault();

    this.setState({
      isSending: true,
      buttonText: 'Sending...',
    });

    sendConsoleCommand(
      this.props.model.command,
      () => {
        this.setState({
          isSending: false,
          buttonText: 'Send Command',
          statusMessage: 'Command completed',
        });
      },
      (error) => {
        this.setState({
          isSending: false,
          buttonText: 'Send Command',
          statusMessage: `Command failed: ${error}`,
        });
      }
    );
  };

  myOnChangeInput = (event) => {
    this.props.setProperty(event.target.name, event.target.value);
  };

  render() {
    const { model } = this.props;

    return (
      <div>
        <form onSubmit={this.myOnSubmit}>
          <label htmlFor='command'>Command</label>
          <br />
          <input
            name='command'
            id='command'
            value={model.command}
            onChange={this.myOnChangeInput}
          />
          <br />
          <button type='submit'>{this.state.buttonText}</button>
          <Spinner isShow={this.state.isSending} />
          <div>{this.state.statusMessage}</div>
        </form>
      </div>
    );
  }
}

export default reformed()(ConsoleInputForm);
