import { Component } from 'react';
import cx from 'classnames';
import Spinner from 'react-spinkit';
import PrimaryButton from './components/PrimaryButton/PrimaryButton';
import PeekavilleLogoBanner from './PeekavilleLogoBanner';
import ConsentCaptureForm from './ConsentCaptureForm';
import { recordConsentAndCreateAccount } from './BackendInterface';
import tracker from './tracker-interface';
import mainStreet from './images/bkg-peekaville-main-street.jpg';
import styles from './SCSS/ParentalConsent.module.scss';
import dialogStyles from './SCSS/Dialog.module.scss';
import appStyles from './SCSS/App.module.scss';

class ParentalConsent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      submitState: 'IDLE',
      errorText: '',
      errorObject: null,
      savedRecord: null,
    };
  }

  handleSubmit = async (accountAndConsentParams) => {
    const query = new URLSearchParams(this.props.location.search);
    const record = {
      oid: query.get('oid'),
      ...accountAndConsentParams,
    };

    this.setState({
      submitState: 'PENDING',
      savedRecord: record,
    });

    try {
      const creationResults = await recordConsentAndCreateAccount(record);
      const { licenseLevel, login, password } = creationResults;
      this.setState({
        submitState: 'SUCCESS',
        login,
        password,
        licenseLevel,
      });
    } catch (error) {
      this.setState({
        submitState: 'ERROR',
        errorText: error.message,
        errorObject: error,
      });
    }
  };

  onTryAgain = () => {
    this.setState({ submitState: 'IDLE' });
  };

  getDynamicContent = () => {
    const insideComponent = () => {
      switch (this.state.submitState) {
        case 'IDLE':
          return (
            <ConsentCaptureForm
              onSubmit={this.handleSubmit}
              savedRecord={this.state.savedRecord}
            />
          );
        case 'PENDING':
        default:
          return (
            <div className={styles.spinnerContainer}>
              <Spinner />
            </div>
          );
      }
    };

    switch (this.state.submitState) {
      case 'IDLE':
      case 'PENDING':
        return (
          <div className={dialogStyles.dialog}>
            <div className={dialogStyles.dialogTitle}>
              Create an Account for Your Child
            </div>
            <div className={dialogStyles.dialogText}>
              <p>
                While your child is playing, we collect data on which games they
                play and other game characteristics such as how many berries
                they've earned. All the personally-identifiable information that
                is collected is stored in encrypted form.
              </p>
              <p>
                At no point will they be presented with advertisements, nor will
                their information be shared with any third parties for marketing
                purposes.
              </p>
              <p>
                For the full details, please review our
                <wbr />
                &nbsp;
                <a
                  href='https://peekapak.com/privacyPolicy'
                  rel='noopener noreferrer'
                  target='_blank'
                  className={appStyles.textLink}
                >
                  privacy policy
                </a>{' '}
                <wbr />
                and our <wbr />
                <a
                  href='https://peekapak.com/termsOfUse'
                  rel='noopener noreferrer'
                  target='_blank'
                  className={appStyles.textLink}
                >
                  terms of use
                </a>
                .
              </p>
              {insideComponent()}
            </div>
          </div>
        );
      case 'SUCCESS':
        return (
          <div className={dialogStyles.dialog}>
            <div className={dialogStyles.dialogTitle}>
              Account Successfully Created!
            </div>
            <div className={dialogStyles.dialogText}>
              <p>
                Please look for a confirmation email with your child's login
                name and password, for your records.
              </p>
              <p>Login name:</p>
              <div className={styles.login}>{this.state.login}</div>
              <p>Password:</p>
              <div className={styles.password}>{this.state.password}</div>
              <p>Login at:</p>
              <div className={styles.url}>{document.location.host}</div>
              {this.state.licenseLevel === 'DEMO' && (
                <div>
                  <p>
                    <strong>Please note</strong>: This is <strong>DEMO</strong>{' '}
                    account and allows your child to play only through the first
                    quest and two mini-games. To gain access to all available
                    quests and games, please sign up for an account from a
                    licensed partner school or library. You can also sign up on
                    our
                    <wbr />
                    &nbsp;
                    <a
                      href='https://forms.gle/kPE2DL93WqtX94mn7'
                      className={appStyles.textLink}
                      rel='noopener noreferrer'
                      target='_blank'
                    >
                      waitlist
                    </a>
                    <wbr />
                    &nbsp; to purchase access to the full game as an individual
                    family.
                  </p>
                </div>
              )}
            </div>
          </div>
        );
      default:
        return (
          <div className={styles.errorMessage}>
            <p>{this.state.errorText}</p>
            {this.state.errorObject.code === 'REQUEST_ALREADY_USED' && (
              <div className={cx(dialogStyles.dialog, styles.resetFontColor)}>
                <div className={dialogStyles.dialogTitle}>Login details</div>
                <div className={dialogStyles.dialogText}>
                  <p>
                    Please look for a confirmation email with your child's login
                    name and password which was sent for your records.
                  </p>
                  <p>Login name:</p>
                  <div className={styles.login}>
                    {this.state.errorObject.login}
                  </div>
                  <p>Password:</p>
                  <div className={styles.password}>
                    {this.state.errorObject.password}
                  </div>
                  <p>Login at:</p>
                  <div className={styles.url}>{document.location.host}</div>
                </div>
              </div>
            )}
            <PrimaryButton orange small type='submit' onClick={this.onTryAgain}>
              Try Again
            </PrimaryButton>
          </div>
        );
    }
  };

  render() {
    return (
      <div className={styles.parentalConsent}>
        <div className={styles.logoContainer}>
          <PeekavilleLogoBanner />
        </div>
        {this.getDynamicContent()}
        <div className={styles.bottomBanner}>
          <img src={mainStreet} alt='img' className={styles.topImg} />
        </div>
      </div>
    );
  }
}

export default tracker.withPageView(ParentalConsent);
